@import "../helpers/mixins";

.roster-table {
  @include grid-basics;
  min-width: 100%;
  grid-template-columns: minmax(200px, 3fr) repeat(7, 3fr);

  border: 1px solid var(--bulma-grey-darker);

  .roster-headers, .roster-row {
    border-bottom: 1px solid;

    > div:not(.shifts) {
      padding: .25em .25em .25em .25em;
    }
  }

  .roster-row {
    &:nth-of-type(even) {
      background-color: var(--bulma-grey-lightest);
    }

    > :first-child {
      border-right: 1px solid var(--bulma-grey-light);
    }
  }

  .chart-container canvas {
    max-width: 100%;
  }

  .shift-button {
    padding-top: 2px;
  }

  .shifts {
    border-right: 1px solid var(--bulma-grey-lighter);
    display: flex;
    flex-direction: column;

    .add-shift {
      padding: 0.5em 0.75em;
      height: 100%;
    }
  }

  .existing-shift {
    font-variant-numeric: tabular-nums;
    padding: 0.5em 0.75em;

    input {
      padding: 0.5em;
    }
  }

  .requested-driver-shift {
    background-color: #bce4c7;
    a, i {
      color: black;
    }
  }

  .confirmed-driver-shift {
    background-color: #48ca6b;
    a, i {
      color: white;
    }
  }

  .change-requested-driver-shift {
    background-color: #fc8641;
    a, i {
      color: white;
    }
  }

  .removed-driver-shift {
    background-color: #f55442;
    a, i {
      color: white;
    }
  }

  .away-driver-shift {
    background-color: #707070;

    a, i {
      color: white;
    }
  }
}
