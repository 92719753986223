.availability-visualisation {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  .bar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
