.gm-style-iw-chr button {
  width: 24px !important;
  height: 24px !important;
}

.gm-style-iw-chr button span {
  width: 20px !important;
  height: 20px !important;
  margin: 0 !important;
}

.gm-style-iw-d {
  padding-right: 12px;
  padding-bottom: 12px;
}