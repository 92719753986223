[data-tooltip] {
  position: relative;

  &::before, &::after {
    position: absolute;
    font-size: small;

    opacity: 0;
    z-index: 7;
  }

  &:hover {
    &::before, &::after {
      opacity: 1;
      transition: opacity 0.25s ease-in 0s;
    }
  }

  &:hover::after {
    content: attr(data-tooltip);
    color: var(--bulma-primary-invert);
    background-color: var(--bulma-grey-dark);
    padding: 12px;
    border-radius: 6px;
    white-space: pre;
  }

  &:hover::after {
    top: 2em;
    left: 50%;
    transform: translate(-50%, 12px);
  }

  &.has-tooltip-arrow {
    &:hover::before {
      content: "";
      top: 2em;
      left: 50%;
      transform: translate(-50%, 0);
      border: 6px solid;
      border-color: transparent transparent var(--bulma-grey-dark) transparent;
    }
  }

  &.has-tooltip-left {
    &:hover::after {
      top: 0;
      left: 0;
      transform: translateX(calc(-100% - 6px));
    }

    &.has-tooltip-arrow {
      &:hover::before {
        content: "";
        top: 0;
        left: 0;
        transform: translate(-50%, 12px);
        border: 6px solid;
        border-color: transparent transparent transparent var(--bulma-grey-dark);
      }
    }
  }
}
