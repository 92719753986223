$list-item-hover-color: var(--bulma-scheme-main-ter);
@import "../../../node_modules/bulma-list/sass/bulma-list.scss";

.has-flex-1-fields {
  > .field {
    flex: 1 !important;
  }
}

// Typography utilities
.has-text-monospaced-numbers {
  font-variant-numeric: tabular-nums;
}

// Utilities for Bulma's internal radius variables
.is-rounded {
  border-radius: var(--bulma-radius);

  &-small {
    border-radius: var(--bulma-radius-small);
  }

  &-large {
    border-radius: var(--bulma-radius-large);
  }
}

// Use in conjunction with Bulma's flexible column sizing classes to constrain resizing behavior
.sidebar-column {
  min-width: 240px;
  max-width: 350px;
}

.sidebar-column > .sticky-sidebar {
  position: sticky;
  top: 0;
}

tr.hidden-with-size {
  visibility: collapse;
}

// Because .is-fullwidth only applies with certain elements/classes
.is-fullwidth {
  width: 100%;
}
