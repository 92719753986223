.environments-box,
.environments-box::after {
    position: fixed;
    z-index: 99999;
    pointer-events: none;
    border-radius: 4px;
    inset: 2px;

    /* is-tablet */
    @media (min-width: 768px) {
        inset: 3px;
    }

    /* is-desktop */
    @media (min-width: 1024px) {
        inset: 4px;
    }

    /* is-desktop-super-wide */
    @media (min-width: 2048px) {
        inset: 5px;
    }
}

.environments-box {
    border: 3px solid hsl(2, 97%, 51%);

    /* is-tablet */
    @media (min-width: 768px) {
        border-width: 4px;
    }

    /* is-desktop */
    @media (min-width: 1024px) {
        border-width: 5px;
    }

    /* is-desktop-super-wide */
    @media (min-width: 2048px) {
        border-width: 6px;
    }
}

.environments-box::after {
    border: 3px dashed hsl(26, 93%, 52%);
    content: "";

    /* is-tablet */
    @media (min-width: 768px) {
        border-width: 4px;
    }

    /* is-desktop */
    @media (min-width: 1024px) {
        border-width: 5px;
    }

    /* is-desktop-super-wide */
    @media (min-width: 2048px) {
        border-width: 6px;
    }
}
