$colour-gutter-width: 8px;

.dispatch-dashboard {
  min-height: calc(100vh - #{var(--bulma-navbar-height)});
}

.dispatch-dashboard.is-disconnected {
  filter: grayscale(60%);
}

.dispatch-dashboard.highlight-restricted .has-driving-curfew {
  background: #FFE68A;
  border-radius: 4px;
}

.order {
  display: flex;
  flex-direction: column;
  transition: all 0.25s cubic-bezier(.08,.82,.17,1);
  box-sizing: border-box;

  &:not(:first-child) {
    border-top: 1px solid rgb(74, 74, 74);
  }

  .order-summary {
    display: flex;
    align-items: center;
  }

  > .columns {
    cursor: pointer;
    z-index: 1;
    position: relative;
    padding-left: $colour-gutter-width;

    .order-drop-target {
      border-radius: 3px;
    }

    &:hover {
      .order-drop-target {
        background-color: rgba(0, 0, 0, 0.2);
        color: var(--bulma-black);
      }
    }

  > .column {
      flex-basis: auto;
    }
  }

  table {
    background-color: transparent;
  }
}

.driver {
  .card-header {
    cursor: pointer;

    .driver-start-time,
    .driver-end-time {
      border-radius: 4px;
      padding: 0 2px;
    }

    .driver-start-time {
      background: #ABF9AB;
    }

    .driver-end-time {
      background: #FFE892;
    }
  }

  .order {
    > .columns {

      .fa-arrow-right {
        opacity: 0.4;
      }

      > .column {
        flex-basis: auto !important;
      }
    }

    table tr > :first-child {
      padding-left: 0;
    }

    table tr > :last-child {
      padding-right: 0;
    }
  }
}

.category {
  display: flex;

  &.is-free-driver {
    background-color: rgb(205, 231, 255);
  }

  &.is-driver-free-at-collecting {
    background-color: rgb(252, 255, 206);
  }

  &.is-driver-free-at {
    background-color: rgb(211, 255, 205);
  }
}

@mixin highlight {
  .has-text-grey,.has-text-light-grey {
    color: var(--bulma-dark) !important;
  }

  color: black !important;
}

.order .driver-acknowledgement-indicator {
  width: $colour-gutter-width;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
}

.order.unacknowledged-by-driver .driver-acknowledgement-indicator {
  background-color: rgb(229, 0, 124);
}

.order.ready-for-pickup .pickup-time {
  background-color: rgb(44, 255, 204);
}

.maplink {
  position: relative;

  .same-address-indicator {
    color: #F39AC6;
    position: absolute;
    top: 2px;
    right: 0;
    font-size: 8px;
  }

  a {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 15px;
    color: #000000;
  }

  &:hover a {
    background: rgba(0, 0, 0, 0.2);
  }
}

.order {
  .maplink {
    display: none;
    backdrop-filter: blur(999px);
  }

  &:hover .maplink {
    display: block;
  }
}

.has-draggable-hover.group-placeholder,
.has-draggable-hover.group:not(:has(.is-dragged-node)) .card,
.has-draggable-hover:not(:has(.is-dragged-node), .group) {
  box-shadow: 0 0 4px 4px #1CA71C !important;
}

.has-drag-error {
  box-shadow: 0 0 4px 4px #FF2626 !important;
}

.drag-overlay {
  opacity: 0.8;
  pointer-events: none;

  body:has(&) * {
    cursor: grabbing !important;
  }
}

.order.ten-minutes-overdue > .columns  {
  background: rgb(255, 236, 168);

  @include highlight;
}

.order.fifteen-minutes-overdue > .columns  {
  background: rgb(255, 189, 244);

  @include highlight;
}

.order.thirty-minutes-overdue > .columns  {
  background: rgb(251, 163, 73);

  @include highlight;
}

.order.unacknowledged-by-restaurant > .columns {
  background: rgb(255, 146, 146);

  @include highlight;
}

.order.operations-exception > .columns  {
  background: rgb(240, 217, 9);

  @include highlight;
}

.order .dash-note {
  background: rgb(255, 206, 92);
}

.order.ten-minutes-overdue:not(.fifteen-minutes-overdue) .dash-note,
.order.operations-exception .dash-note {
  background: rgb(253, 105, 228);
}

.order .dash-note-input {
  min-width: 5rem;
  border: 1px solid var(--bulma-border);
}

.heatmap {
  position: sticky;
  top: 0px;
  z-index: 5;
  overflow-x: auto;
  box-shadow: 0 0 10px rgba(90, 90, 90, 0.25);

  .column {
    min-width: 7ch;

    a {
      height: 100%;
      width: 100%;
      display: inline-block;
      color: black;

      span {
        display: block;
      }
    }
  }

  .later {
    flex-grow: 0;
    flex-shrink: 1;
    min-width: 4ch;

    > .dash-time-slot {
      display: flex;
      justify-content: center;

      * {
        align-self: center;
      }
    }
  }

  @mixin slot($background, $foreground: black, $text-weight: 400) {
    span {
      background: $background;
      color: $foreground;
      font-weight: $text-weight;
    }
  }

  .clear-time-slot {
    @include slot(#ffffff, rgba(0, 0, 0, 0.7));
  }

  .grey-time-slot {
    @include slot(#D0D0D0, rgba(0, 0, 0, 0.7));
  }

  .muted-green-time-slot {
    @include slot(#BAF4CE, rgba(0, 0, 0, 0.7));
  }

  .bright-green-time-slot {
    @include slot(#26D162, #000000, 500);
  }

  .yellow-time-slot {
    @include slot(#FFD11A, #000000, 500);
  }

  .orange-time-slot {
    @include slot(#D4690D, #ffffff, 500);
  }

  .red-time-slot {
    @include slot(#A90000, #ffffff, 500);
  }

  .closed {
    display: none !important;
  }

  .panicked {
    color: var(--bulma-light) !important;
    background: #ef3c24;
  }

  // Darker background for panicked slots after the first eight (65+ minutes panic)
  .column:nth-child(n+9) .panicked {
    background: #a90000;
  }

  &:hover > .column > a.unpanicked {
    background: #feeeec;
  }

  & > .column:hover ~ .column a {
    background: white;
    color: black !important;
  }
}

.driver-list .driver, .group-list .group {
  @extend .is-one-third;
}

.column.group-placeholder {
  padding-top: 0;
  padding-bottom: 0;
}

.driver,.group {
  .card-header {
    font-size: 1rem;
  }
}

.card.is-card-dashboard {
  --bulma-card-radius: 0.25rem;
  background-color: transparent;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.17);

  .card-content {
    overflow: hidden;
  }

  .card-header-expanded {
    box-shadow: inset 0 0.125em 0.25em rgba(10, 10, 10, 0.1);
  }

  .order,
  .card-header,
  .card-header-expanded,
  .card-header-footer,
  .card-surface {
    background-color: #ffffff;
  }

  .card-header {
    flex-wrap: wrap;
  }

  .card-header-footer {
    flex-basis: 100%;
  }

  .is-large-control {
    // Overrides for /bulma/sass/form/select.sass in this particular case
    &.select select:not([multiple]) {
      padding-inline-end: 1.5em;
    }
    &.select:not(.is-multiple):not(.is-loading):after {
      inset-inline-end: 0.5em;
    }
  }
}

@keyframes unacknowledgedDeliveriesCountPulse {
  0%, 100% { background-color: #FF9292; }
  50% { background-color: #FFEE5F; }
}

.status-bar {
  min-height: 1.5rem;

  .no-dispatcher-warning {
    background: #FF8000;
  }

  .unacknowledged-deliveries-count {
    background: #FF9292;

    animation-name: unacknowledgedDeliveriesCountPulse;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-delay: 120s;
  }

  .thirty-minutes-overdue-count {
    background: rgb(251, 163, 73);
  }
}

.dash-separator {
  width: 100%;
  border-bottom: 1px solid #5E5E5E;
  margin: 0 1rem;
}

.is-half-opacity {
  opacity: 0.5;
}

.blob {
	background: black;
	border-radius: 50%;
	height: 1px;
	width: 1px;

	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 150px rgba(255, 255, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 0, 0);
	}
}
