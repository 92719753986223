.order-display {
  .refunded {
    .quantity, .product-title, .price {
      text-decoration: line-through;
    }
  }

  .price, .quantity {
    text-align: right;
  }

  table {
    th {
      font-size: 0.5em;
      text-transform: uppercase;
      color: var(--bulma-grey);
    }
  }

  li.addition::before {
    content: "+ ";
  }

  li.replacement::before {
    content: "~ ";
  }
}
