@mixin grid-basics {
  display: grid;
  width: fit-content;

  .subgrid {
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: subgrid;
  }
}
