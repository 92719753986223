.shift-report-table {
  .finalized {
    background-color: #48ca6b;

    a, i {
      color: white;
    }
  }

  .not-submitted {
    background-color: #fc8641;

    a, i {
      color: white;
    }
  }
}
