@import '@algolia/autocomplete-theme-classic/dist/theme';
@import '@algolia/autocomplete-plugin-tags/dist/theme';

.aa-Tags {
  margin-right: 8px;
}

.aa-Tags:empty {
  display: none;
}

.aa-TagsList {
  display: flex;
  margin: 0 calc((var(--aa-spacing) / 5) * -1);
}

.aa-Tag {
  background-color: rgba(
    var(--aa-selected-color-rgb),
    var(--aa-selected-color-alpha)
  );
  align-items: center;
  display: flex;
  margin: 0 calc(var(--aa-spacing) / 5);
  padding-left: var(--aa-spacing-half);
  border-radius: calc(var(--aa-spacing) / 4);
}


.aa-Tag:hover,
.aa-Tag:focus {
  background-color: rgba(var(--aa-selected-color-rgb), 0.55);
}

.aa-TagLabel {
  font-size: 0.8em;
}

.aa-TagRemoveButton {
  cursor: pointer;
  padding: 0;
  border: 0;
  background: none;
}

.aa-TagRemoveButton svg {
  color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  margin: 0;
  margin: calc(var(--aa-spacing) / 2.5);
  stroke-width: var(--aa-icon-stroke-width);
  width: calc(var(--aa-action-icon-size) / 1.5);
}

.aa-TagRemoveButton:hover svg,
.aa-TagRemoveButton:focus svg {
  color: rgba(var(--aa-text-color-rgb), var(--aa-text-color-alpha));
}

@media (hover: none) and (pointer: coarse) {
  .aa-TagRemoveButton:hover,
  .aa-TagRemoveButton:focus {
    color: inherit;
  }
}

.aa-Form {
  flex-direction: row !important;

  &.input {
    border-radius: var(--bulma-radius);
    border: 1px solid var(--bulma-border);

    padding: 0;

    &:focus-within {
      box-shadow: var(--bulma-input-focus-shadow-size) hsla(var(--bulma-input-focus-h), var(--bulma-input-focus-s), var(--bulma-input-focus-l), var(--bulma-input-focus-shadow-alpha));
      border: 1px solid hsl(var(--bulma-input-focus-h),var(--bulma-input-focus-s),var(--bulma-input-focus-l));
    }

    .aa-SubmitButton, .aa-SubmitButton svg, .aa-LoadingIndicator, .aa-LoadingIndicator svg, .aa-ClearButton, .aa-ClearButton svg {
      width: auto;
    }

    .aa-SubmitButton svg {
      position: relative;
      top: 2px;
    }
  }
}

.aa-Panel {
  z-index: 4; /* above Bulma combobox carets */
}

.aa-Item .description {
  color: var(--bulma-text-50);
  font-size: 90%;
  padding-top: 4px;
}
