@charset "utf-8";

@use "sass:math";

@use "environments";

@use "../../../node_modules/bulma/sass" with(
  // override the default body overflow-y, which was causing an unused scrollbar on every page
  // we use .main-content for most pages now
  $body-overflow-y: auto,

  $family-sans-serif: 'Geograph, sans-serif',
  // Geograph only has regular and medium weights, so set everything over medium (500)
  // to be medium weight
  $title-weight: 500,
  $weight-bold: 500,
  $weight-semibold: 500,
  $button-weight: 400,

  $link: rgb(72, 95, 199),
  $primary: rgb(239, 60, 36),

  $navbar-breakpoint: 820px,
  $button-text-decoration: none,

  $breadcrumb-item-active-color: rgb(0, 0, 0),

  $tabs-link-active-border-bottom-color: var(--bulma-primary),
  $tabs-link-active-color: var(--bulma-primary),
  $tabs-toggle-link-active-background-color: var(--bulma-primary),
  $tabs-toggle-link-active-border-color: var(--bulma-primary),
  $tabs-toggle-link-active-color: var(--bulma-primary-invert),
);
@use "../../../node_modules/bulma/sass/utilities/mixins" as bulma-mixins;
@use "bulma-responsive-tables/bulma-responsive-tables";

@font-face {
  font-family: "Geograph";
  src: url("../delivereasy-ui/fonts/geograph-web-regular.woff2"),
       url("../delivereasy-ui/fonts/geograph-web-regular.woff");
  font-weight: normal;
}

@font-face {
  font-family: "Geograph";
  src: url("../delivereasy-ui/fonts/geograph-web-regular-italic.woff2"),
       url("../delivereasy-ui/fonts/geograph-web-regular-italic.woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Geograph";
  src: url("../delivereasy-ui/fonts/geograph-web-medium.woff2"),
       url("../delivereasy-ui/fonts/geograph-web-medium.woff");
  font-weight: 500;
}

:root {
  --bulma-duration: 125ms;
}

body {
  // In order to suppress Geograph's alternate form for the X in "1X2"
  font-variant-ligatures: no-contextual;
}

b,
strong,
th {
  font-weight: var(--bulma-weight-bold);
}

th,
td {
  &.border-left {
    border-left: 1px var(--bulma-table-cell-border-style) var(--bulma-table-cell-border-color) !important;
  }

  &.border-right {
    border-right: 1px var(--bulma-table-cell-border-style) var(--bulma-table-cell-border-color) !important;
  }
}

/* TODO: Talk to Fannin, maybe delete */
.is-scroll-y {
  overflow-y: scroll;
}

.fills-container-vertically {
  height: 100%;
}

.is-fixed-height {
  position: fixed;
  width: inherit;

  top: 80px;
  bottom: 0px;
}

@import "./bulma_extensions.scss";

@import "admin/chat";
@import "admin/dispatch";
@import "admin/algolia";
@import "admin/maps";
@import "admin/planners";
@import "admin/shift_reports";
@import "admin/tooltip";
@import "admin/orders";

@mixin scrollbar-style {
  -ms-overflow-style: auto;
  scrollbar-color: hsla(0, 0%, 40%, 0.5) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar-thumb {
    background-color: hsla(0, 0%, 40%, 0.5);
    border-radius: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
}

%subtle-scrollbar-style {
  @media (hover: hover) {
    @include scrollbar-style;

    scrollbar-color: transparent transparent;

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &:hover {
      scrollbar-color: hsla(0, 0%, 40%, 0.5) transparent;
      transition-delay: 0s;

      &::-webkit-scrollbar-thumb {
        background-color: hsla(0, 0%, 40%, 0.5);
      }
    }
  }
}

[data-clipboard-target=clipboard] {
  cursor: pointer;
}

.grid-main {
  display: grid;
  grid-template-areas:
    "header header header"
    "sidenav content sidebar"
  ;
  grid-template-columns: max-content 1fr max-content;
  overflow: hidden;
}

.main-header {
  grid-area: header;
  position: sticky;

  .home-link > img {
    height: 56px;
  }

  // Bulma Navbar customisation
  &.navbar {
    padding: 0 0.15rem 0 0;

    > .navbar-brand a.home-link.navbar-item:hover {
      background-color: var(--bulma-black-ter);
    }

    .navbar-item,
    .navbar-link {
      padding: 0.3rem 0.15rem;
    }

    .navbar-item {
      > .button,
      > .buttons .button {
        background-color: var(--bulma-black);
        border-color: transparent;
        color: var(--bulma-light);

        &:hover, &.is-hovered,
        &:focus, &.is-focused, &.is-active {
          background-color: var(--bulma-grey-darker);
        }
      }

      .has-addons {
        .button {
          border-color: var(--bulma-grey-darker);
        }
      }
    }


    .dropdown {
      .dropdown-content {
        background-color: var(--bulma-black);
        max-height: 90vh;
        overflow-y: auto;

        .dropdown-item,
        label {
          color: var(--bulma-white);
          background-color: var(--bulma-black);

          &:hover {
            color: var(--bulma-white);
          }
        }

        a.dropdown-item:hover {
          background-color: var(--bulma-primary);
        }

        .dropdown-divider {
          background-color: var(--bulma-grey-darker);
        }
      }
    }
  }
}

%viewport-height {
  height: 100vh;

  .main-header ~ & {
    height: calc(100vh - #{var(--bulma-navbar-height)});
  }
}

.main-content {
  @extend %viewport-height;
  @extend %subtle-scrollbar-style;

  grid-area: content;
  overflow-y: scroll;
}

%sidepane {
  @extend %viewport-height;

  display: flex;
  position: relative;
  flex-direction: column;

  .sidebar-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    width: 25vw;
    min-width: 350px;
    max-width: 400px;
    transition: all 0.25s cubic-bezier(.25,.46,.45,.94);

    @include bulma-mixins.touch {
      max-width: 100vw;
      width: 100vw;
      transition: none;
    }

    form,
    .content-toggle {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .content-toggle {
      height: 100%;
    }
  }

  &.is-collapsed {
    .sidebar-content {
      width: 0px;
      min-width: 0px;
    }
  }

  &.is-collapsed-touch {
    @include bulma-mixins.touch {
      .sidebar-content {
        width: 0px;
        min-width: 0px;
      }
    }
  }
}

.main-sidebar {
  @extend %sidepane;

  $padding: 0.5em;

  grid-area: sidebar;
  box-shadow: var(--bulma-shadow);

  @include scrollbar-style;

  > section,
  > header,
  > footer {
    padding: $padding;
  }

  .card-content {
    &.scrollable {
      overflow-y: auto;
      flex: 1 1;
      flex-basis: 0;
    }
  }
}

.main-sidenav {
  @extend %sidepane;

  $padding: 0.5em;

  grid-area: sidenav;

  @include bulma-mixins.touch {
    position: absolute;
    left: 0;
    top: var(--bulma-navbar-height);
    width: 100%;
    height: calc(100vh - var(--bulma-navbar-height));
    z-index: 9;

    .nav-items {
      gap: 0.5em;
    }
  }

  html:not(.has-collapsed-nav) & {
    .nav-items {
      > .nav-category {
        .button.is-active:not(:hover) {
          background-color: revert;
        }

        .button.is-active {
          font-weight: 500;
        }
      }
    }

    .nav-items,
    .bottom-button {
      .button {
        width: 100%;
      }

      .dropdown-label {
        display: none;
      }
    }
  }

  html.has-collapsed-nav & {
    .nav-items {
      align-items: flex-start;
    }

    .nav-items,
    .bottom-button {
      .button {
        width: auto !important;

        .icon {
          // Copy of bulma's own method for squaring buttons
          // originally used when the icon is :first-child:last-child
          margin-left: calc(#{math.div(-1, 2)} * var(--bulma-button-padding-horizontal) - var(--bulma-button-border-width));
          margin-right: calc(#{math.div(-1, 2)} * var(--bulma-button-padding-horizontal) - var(--bulma-button-border-width));
        }
      }

      .nav-category-label {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      .dropdown-label {
        font-size: 0.75rem;
        text-transform: uppercase;
        background-color: var(--bulma-grey-lighter);
        color: var(--bulma-grey-dark);
        font-weight: 500;
        padding: 0.5rem 1.25rem;
      }

      .nav-category-submenu {
        display: none;
      }
    }

    .bottom-button .icon {
      transform: rotate(180deg);
    }
  }

  .nav-items {
    @extend %subtle-scrollbar-style;

    padding: $padding;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 1px;

    .button {
      &:hover,
      &.is-active {
        background-color: rgba(180, 180, 180, 0.3);
      }

      &.is-active {
        font-weight: 500;
      }
    }
  }

  .bottom-button {
    padding: $padding;
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    position: sticky;
    bottom: 0;
  }

  .dropdown.is-custom-dropdown {
    $hover-padding: 2em;

    position: static;

    .dropdown-menu {
      min-width: 220px;
      padding-top: $hover-padding;
      padding-right: $hover-padding;
      padding-bottom: $hover-padding;

      .button {

        &:hover,
        &.is-active {
          background-color: rgba(215, 215, 215, 0.3);
        }
      }
    }

    .dropdown-content {
      overflow: hidden;
      border: 1px solid var(--bulma-grey-lighter);
    }

    .dropdown-items {
      display: flex;
      flex-direction: column;
      gap: 1px;
      padding: 4px;
    }
  }

  .nav-category-submenu {
    display: flex;
    flex-direction: column;
    gap: 1px;

    .button {
      background-color: transparent;
      color: var(--bulma-grey-darker);

      > span {
        // Combines Bulma rem unit icon sizing (size-4) with calculations
        // from Bulma's own code for icon margin and gaps inside buttons
        // to align button text with .nav-category-label text
        padding-left: calc(var(--bulma-size-4) - 0.5 * var(--bulma-button-padding-horizontal) - var(--bulma-button-border-width) + var(--bulma-button-padding-horizontal) * 0.25);
      }
    }
  }
}

.menu.has-collapsing-labels .menu-label:not(.is-active)+.menu-list {
  display: none;
}

.columns {
  &.is-vertical {
    flex-direction: column;

    > .column.is-vert-1 {
      height: math.div(100%, 12%);
    }
  }
}

// we may want to generalise this to all form.button_to elements, but until we have time to regression-test, we'll just do the action buttons inside tables
table.table form.button_to {
  display: inline-block;
}

.one-line-per-collection-item label {
  display: block;
}

.one-line-per-collection-item input {
  margin-right: 0.5em;
}

.has-vertical-scrollbars {
  overflow-y: auto;

  @extend %subtle-scrollbar-style;
}

.field_with_errors input, .select .field_with_errors select, .field_with_errors textarea {
  border-color: var(--bulma-danger);
}

// normally applied by the .label:not(:last-child) rule, but <%= f.label %> will produce a div.field_with_errors around the label tag, so it's always the last-child
.field_with_errors label {
  margin-bottom: 0.5em;
}

$phone-widget-width: 600px;
$phone-widget-height: 750px;

.navbar-phone-widget {
  width: $phone-widget-width;
  height: $phone-widget-height;
  max-height: calc(100vh - #{var(--bulma-navbar-height)});
  left: (68px - $phone-widget-width);
}

form.button_to {
  display: inline-block;
}

.tag.is-hoverable {
  cursor: pointer;

  &:not(:hover) { background: none; }
}

.sticky-header {
  position: sticky; top: 0;
  z-index: 2;
}

.out-of-stock-button {
  margin-right: 10px;
}

.radio-margin {
  margin-right: 15px;
}

.bulk-images-table {
  @include grid-basics;
  grid-template-columns: 2em 1fr 1fr repeat(3, 300px);
  grid-template-rows: 2em;

  .checkbox {
    margin: 0.5em;
  }

  .alternating-row {
    &:nth-of-type(even) {
      background-color: var(--bulma-grey-lightest);
    }

    > div {
      align-content: center;
      text-align: center;
      margin: 0.5em;
    }
  }
}

.is-vbottom {
  align-items: flex-end;
}

.column.is-vcentered {
  align-self: center;
}

.is-button-table {
  td:not(.is-row-header) {
    padding: 0;

    a {
      display: inline-block;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

// Restaurants list
.restaurants-list {
  .list-item-image {
    width: 9rem;

    @include bulma-mixins.mobile {
      width: 100% !important;
    }

    .image {
      background-size: cover;
    }
  }

  @include bulma-mixins.mobile {
    .list-item-controls {
      width: 100%;

      .button {
        flex-grow: 1;
      }
    }
  }
}


.delivery-times-box {
  background-color: #f7f7f7;
  border: solid 3px white;
}


input:checked+label.is-black-when-checked {
  @extend .is-black;
}

.hover-image {
  position: relative;

  &:hover > .hover-buttons {
    visibility: visible;
    opacity: 1;
  }
}

.hover-buttons {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.not-top-aligned {
  vertical-align: unset;
}

.driver-statement tbody td {
  border: 0px;
}

input[type="checkbox"] {
  &.switch {
    display: none;

    + .switch-controls {
      user-select: none;
      display: inline-block;
      border: 1px solid var(--bulma-grey);
      border-radius: 1em;
      width: 2em;
      height: 1em;
      vertical-align: top;
    }

    + .switch-controls {
      &::after {
        content: "";
        position: relative;
        vertical-align: middle;
        display: inline-block;
        border-radius: 1em;
        width: 1em;
        height: 1em;
        background: white;
        box-shadow: 1px 1px 2px var(--bulma-grey);
        transition: left 0.1s ease-in 0s;
      }
    }

    &:checked {
      + .switch-controls {
        background-color: var(--bulma-primary);

        &::after {
          top: calc(-0.5em + 0.5px);
          left: calc(1em - 0.5px);
        }
      }
    }

    &:not(:checked) {
      + .switch-controls {
        background-color: var(--bulma-grey-lighter);

        &::after {
          top: calc(-0.5em + 0.5px);
          left: -1px;
        }
      }
    }
  }
}

input:where([type="checkbox"][role="switch"]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  font-size: inherit;
  width: 2em;
  height: 1em;
  box-sizing: content-box;
  border: 1px solid;
  border-radius: 1em;
  vertical-align: text-bottom !important; // Bulma sets this to baseline pretty aggressively
  margin: auto;
  color: inherit;
}

input:where([type="checkbox"][role="switch"])::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  box-sizing: border-box;
  width: 0.7em;
  height: 0.7em;
  margin: 0 0.15em;
  border: 1px solid var(--bulma-danger);
  border-radius: 50%;
  background: var(--bulma-danger);
  transition: 0.05s ease-in-out;
  transition-property: background-color border-color left;
}

input:where([type="checkbox"][role="switch"]):checked::before {
  left: 1em;
  background: var(--bulma-success);
  border: 1px solid var(--bulma-success);
}

[data-controller~="track-changes"] {
  :not(:has([data-has-changed])) {
    .hidden-when-unchanged {
      visibility: hidden;
    }
  }
}

.expandable-category-list {
  &:has([data-has-changed]) {
    .category-header {
      .hidden-when-unchanged {
        visibility: visible;
      }
    }
  }
}

.is-editable-outlined {
  border: 1px solid transparent;
}

.is-editing-outlined {
  border: 1px solid hsl(49, 93%, 52%);
}

.save-button {
  background-color: hsl(49, 93%, 52%);
}

.is-not-editable {
  background-color: white !important;
  padding-left: 0;
  border: none !important;
  color: hsl(0, 0%, 29%) !important;
}

.has-addons .control:has(+ .control .is-not-editable) .button.is-static,
.has-addons .control:has(.is-not-editable) + .control .button.is-static {
  background-color: white !important;
  border: none !important;
  color: hsl(0, 0%, 29%) !important;
}

#subpage {
  hr {
    background-color: #DBDBDB;
    height: 1px;
  }

  .select::after {
    border-color: hsl(0, 0%, 20%);
  }
}

.disabled-suburb-options {
  .control {
    margin: 0.5rem 0.5rem 0 0;
    padding-bottom: 0.75rem;
    display: inline-block;

    label {
      padding: 0.5rem 0.75rem;
      border-radius: 10px;

      input {
        display: none;
      }

      // the colors & styles are the reverse of what you might expect because the data model wants the *disabled* suburbs, but the UX
      // presents them as *enabled* suburbs. (this is done so when the list of possible suburbs changes new suburbs are enabled by default.)
      background-color: #EEFCF2;
      color: #167E39;

      &:has(> input:checked) {
        background-color: #FFEBEC;
        color: #94000A;
      }
    }
  }
}

.fa-circle-xmark {
  color: #0A0A0A33;
}

.fa-circle-plus {
  color: #0A0A0A33;
}

.is-flex-basis-100 {
  flex-basis: 100%;
}

#images li {
  list-style-type: disc;
}

.is-label-help {
  font-size: 0.75rem;
}

.image-radius {
  border-radius: 6px;
}

.circular-landscape {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
}

.circular-landscape img {
  clip-path: circle();
}

.no-hover {
  pointer-events: none !important;
}

.overflow-anywhere {
  overflow-wrap: anywhere;
}

.dropdown.has-min-width-wide {
  .dropdown-content {
    min-width: 300px;
  }
}

.sound-toggle {
  position: relative;
  font-size: 1.4rem;
  color: #F5F5F5;
  cursor: pointer;

  .speaker {
    display: block;
    margin-right: 10px;
  }

  .wave {
    position: absolute;
    border: 3px solid transparent;
    border-right: 3px solid #F5F5F5;
    border-radius: 50%;
    transition: all 200ms;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &-one {
      width: 50%;
      height: 50%;
    }

    &-two {
      width: 100%;
      height: 100%;
    }
  }

  &.muted .wave {
    width: 50%;
    height: 50%;
    border-width: 0 3px 0 0;
    border-radius: 0;

    &-one {
      transform: rotate(45deg) translate3d(10%, -50%, 0);
    }

    &-two {
      transform: rotate(-45deg) translate3d(10%, 50%, 0);
    }
  }
}

img.index-tile-placeholder {
  content: url("../images/restaurant-card-placeholder.svg");
  background-color: var(--bulma-background);
}

.store-icon {
  color: #333347;
}

@media (min-width: 64em) {
  .store-icon {
    margin-top: 8px;
    margin-left: 7px;
  }
}

@media (max-width: 64em) {
  .store-name {
    justify-content: center;
  }

  .store-icon {
    margin-top: 3px;
  }
}

.is-label-heavy {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .field-label {
    flex-grow: 5;
  }

  .field-body {
    flex-grow: 0;
  }
}
