// Delivereasy Chat UI
// Uses Bulma where possible with custom extensions
@use "../../../../node_modules/bulma/sass/utilities/mixins" as bulma-mixins;

// Area select modal pop-up
#select_areas {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: space-around;
  gap: 1rem;
}

.de-chat {
  $message-colour: #FFBAAF;
  $message-author-colour: var(--bulma-dark);

  $driver-status-active: var(--bulma-primary);
  $driver-status-rostered: var(--bulma-black);
  $driver-status-inactive: var(--bulma-grey-light);

  $unread-badge: $message-colour;
  $unread-badge-muted: var(--bulma-grey-lighter);

  display: grid;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available; // Fix for webkit browsers not sizing around the url bar correctly

  // Removes hidden margins from Bulma columns that were causing
  // the fullscreen layout to have scrolling overflow
  &.columns {
    margin: 0;
  }

  .chat-view {
    overflow: hidden;

    & + .chat-view {
      border-left: 1px solid var(--bulma-grey-lighter);
    }

    // Converasation status
    .is-inactive-driver {
      .list-item-title {
        color: var(--bulma-grey);
      }

      .fa-user-circle {
        color: $driver-status-inactive;
      }
    }

    .is-active-driver {
      .fa-user-circle {
        color: $driver-status-active;
      }
    }

    .is-rostered-driver {
      .fa-user-circle {
        color: $driver-status-rostered;
      }
    }

    &--conversations {
      @include bulma-mixins.tablet {
        min-width: 350px;
        max-width: 500px;
      }

      .logo {
        max-width: 34px;
      }

      .conversation-list {
        $list-padding: 0.5rem;

        &--badge-wrapper {
          padding: 0.5rem;
          align-items: center;
        }

        // Custom list-item styles
        .list-item {
          border-bottom: none; // Remove this when bulma-list supports has-dividers-none
          border-radius: var(--bulma-radius);
          padding: $list-padding;

          .list-item-image {
            margin-right: $list-padding;

            &.free .fa-user-circle {
              color: #1B8EF5;
            }

            &.idle .fa-user-circle {
              color: var(--bulma-grey);
            }
          }

          .list-item-flex-controls {
            display: flex;
            gap: 0.5rem;
          }

          .list-item-right {
            position: absolute;
            right: 0;
            padding: 0 $list-padding;
          }

          .list-item-controls {
            padding: 0 $list-padding;
          }

          &.is-selected {
            background-color: var(--bulma-scheme-main-ter);

            .list-item-controls {
              visibility: visible;
              opacity: 1;
            }
          }
        }

        // Conversation groupings
        .group-heading,
        details.group-collapsible > summary {
          background-color: var(--bulma-scheme-main);
          padding: $list-padding;
        }

        .group-heading {
          position: sticky;
          font-weight: var(--bulma-weight-medium);
          text-transform: uppercase;
          font-size: 0.875rem;
          top: 0;
          z-index: 2;
        }

        details.group-collapsible {
          > summary {
            list-style: none;
            outline: none;

            position: sticky;
            top: 0;
            z-index: 4;

            &::-webkit-details-marker {
              display: none;
            }

            &:hover,
            &:focus {
              > div > .icon {
                background-color: var(--bulma-grey-lightest);
              }
            }

            > div {
              cursor: pointer;
              user-select: none;
              display: flex;

              > .icon {
                border-radius: var(--bulma-radius);
                margin-right: $list-padding;
              }

              &,
              & > .icon,
              & > .icon i {
                transition:
                  transform 0.1s ease-in,
                  background-color 50ms ease-in
                ;
              }

            }
          }


          &[open] {
            > summary {
              > div > .icon > i {
                transform: rotate(90deg);
              }

              > div > .unread-badge {
                visibility: hidden;
                opacity: 0;
              }
            }
          }
        }

        // Unread badge
        .unread-badge {
          width: 1.5rem;
          height: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.625rem;
          font-weight: var(--bulma-weight-medium);
          border-radius: var(--bulma-radius-rounded);
          color: var(--bulma-black);
          background-color: $unread-badge;
          margin-left: auto;
          transition: all 50ms ease-in;

          &.is-muted {
            background-color: $unread-badge-muted;
          }
        }

        // Message timestamp
        .message-timestamp {
          background: #DBDBDB !important;

          &.is-sent {
            background: #141414 !important;
            color: #F5F5F5;
          }
        }
      }
    }

    &--infopane {
      overflow-y: scroll;

      @include bulma-mixins.tablet {
        max-width: 40%;
      }
    }

    .chat-separator {
      text-align: center;
      color: #323232;
      border-top: 1px solid #323232;
      user-select: none;
    }

    .chat-message {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 1em;

      &--details {
        margin-left: 0.5em;
        margin-right: 0.5em;

        // Get rid of all this level stuff later
        .level-item {
          gap: 0.5em;
        }

        .level-item:first-child {
          order: 1;
        }
        .level-item:last-child {
          order: 2;
        }
      }

      &--body {
        background-color: $message-colour;
        padding: 0.5em 1em;
        border-radius: 1.25em;
        margin-bottom: 0.25em;
        max-width: 65ch;
        color: var(--bulma-black);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
        line-height: 1.5;
        white-space: pre-line;
        overflow-wrap: anywhere;

        &.is-author {
          background-color: $message-author-colour;
          color: var(--bulma-white);
        }

        &.is-admin {
          background-color: #BDBDBD;
          color: #212121;
        }

        &:where(.is-author, .is-admin) {
          &,
          & + .chat-message--details {
            align-self: flex-end;
          }

          & + .chat-message--details {
            .level-item:first-child {
              order: 2;
            }

            .level-item:last-child {
              margin-right: 1em;
              order: 1;
            }
          }
        }
      }
    }

    @include bulma-mixins.mobile {
      .button.is-active {
        background-color: transparent;
      }
    }
  }

  .chat-level {
    margin-bottom: 0;
    background-color: var(--bulma-white);
    z-index: 5;
    width: 100%;
    min-height: 100px;
  }

  .chat-bottom-bar {
    min-height: 80px;
    gap: 0.5em;
    > * {
      margin-bottom: 0px;
    }

    textarea.no-resize {
      resize: none;
      max-height: 70vh;
    }
  }

  .chat-element-raised {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    z-index: 5;
  }

  .is-stuck-top {
    position: sticky;
    top: 0px;
  }

  .is-stuck-bottom {
    position: sticky;
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    bottom: 0px;
  }
}
